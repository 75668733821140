import React from 'react';
import pepperLogo from '../assets/partners/pepper.webp'; // Adjust the path as necessary

const RobotSection = () => {
  return (
    <>
      <section className='text-center bg-whtie py-10'>
        <h2 className='text-black font-bold text-center mt-1 mb-8'>Pepper robot humanoidalny</h2>
        <div className='w-11/12 mx-auto flex flex-col lg:flex-row items-center lg:items-start text-black gap-6'>
          
          {/* Pepper Image */}
          <img
            src={pepperLogo}
            alt='Pepper Robot Logo'
            className='w-96 h-96 mb-6 lg:mb-0' // Adjust size as needed
          />
          
          {/* Description Text */}
          <div className='lg:text-left text-center self-center text-xl'>
            <p className='mb-4'>
              Spotkaj Peppera, humanoidalnego robota, który rozpoznaje emocje i wspiera interakcję z człowiekiem! Dzięki współpracy z Snowdog/IronPlane, Pepper będzie z nami przez cały dzień, prezentując swoje zaawansowane możliwości: odpowiadanie na pytania, pozowanie do zdjęć, a nawet przybijanie „żółwika.”
            </p>
            <p className='mb-4'>
              To unikalna okazja, aby zobaczyć, jak technologia wspiera codzienne potrzeby w naturalny sposób. Może uda Ci się dowiedzieć więcej – Pepper to robot pełen tajemnic!
            </p>
            <a
              href='https://www.youtube.com/watch?v=l3jn5JK1OFM'
              target='_blank'
              rel='noopener noreferrer'
              className='text-blue-600 font-bold underline hover:text-blue-300'
            >
              ▶️ Zobacz Peppera w akcji
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default RobotSection;