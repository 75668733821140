import React from "react";
import CloudAI from "../assets/paths/CloudAI_Devfest.webp";
import MentalHealth from "../assets/paths/MentalHealth_Devfest.webp";
import Mobile from "../assets/paths/Mobile_Devfest.webp";
import Software from "../assets/paths/Software_Devfest.webp";

const PathsSection = () => (
  <section className="my-20">
    <h2 className="text-center pb-5">Ścieżki</h2>
    <div className="flex flex-wrap h-auto gap-5 justify-center items-center">
      <img src={Software} className="block w-3/4 sm:w-1/2 md:w-1/3 lg:w-1/4" alt="Software" />
      <img src={Mobile} className="block w-3/4 sm:w-1/2 md:w-1/3 lg:w-1/4" alt="Mobile" />
      <img src={CloudAI} className="block w-3/4 sm:w-1/2 md:w-1/3 lg:w-1/4" alt="CloudAI" />
      <img src={MentalHealth} className="block w-3/4 sm:w-1/2 md:w-1/3 lg:w-1/4" alt="Mental Health" />
    </div>
  </section>
);

export default PathsSection;