import React from 'react';
import { Link } from "react-router-dom";

const Navbar = () => (
    <div>
    <div >
        <ul className="hidden lg:flex flex-col mt-4 lg:flex-row lg:space-x-8 lg:mt-0">
            <li>
                <Link to="/" className='text-white'>Strona główna</Link> 
            </li>
             <li>
                <Link to="/speakers" className='text-white'>Prelegenci</Link> 
            </li>
            {/* <li>
                <Link to='/sponsors' className='text-white'>Sponsors</Link> 
            </li> */}
            <li>
                <Link to='/schedule' className='text-white'>Agenda</Link>
            </li>
            {/* <li>
                <Link to='/location' className='text-white'>Location</Link> 
            </li> */}
            {/* <li>
                <Link to='/code-of-conduct' className='text-white'>Code of Conduct</Link> 
            </li> */}
            <li>
                    {/* Styled button link for buying tickets */}
                    <a 
                        href="https://app.evenea.pl/event/devfestpoznan2024/?source=website-devfest-gdg-poznan" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className='bg-white text-black font-bold py-2 px-4 rounded hover:bg-gray-200 transition'
                    >
                        Kup bilet
                    </a>
            </li>
        </ul>
    </div>
</div> 
)

   



export default Navbar;