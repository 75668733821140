import React from 'react';
import { FaLinkedin } from 'react-icons/fa';
import BioModal from './modal';

const volunteers = [
    {
        id: 1,
        firstName: 'Izabela',
        lastName: 'Raszewska',
        photo: 'izabela_raszewska.webp',
        bio: "A fourth-year engineering student in Telecommunications and Information Technology, passionately developing skills in programming. My interest in programming began a few years ago, but my fascination with computers and the web world has been with me since childhood. I continuously expand my technical competencies and am regularly participating in volunteer work. In my free time, I create games, which combines my technical abilities with creativity and allows me to experiment with new solutions in a programming environment.",
        title: 'Koordynatorka wolontariuszy',
        linkedin: 'https://www.linkedin.com/in/izabela-raszewska/',
    },
    {
        id: 2,
        firstName: 'Aleksandra',
        lastName: 'Benowska',
        photo: 'aleksandra_benowska.webp',
        bio: "Studentka I roku magisterki na kierunku metody ilościowe w ekonomii i systemy informatyczne. Naukowo zainteresowana statystyką i analizą danych. W wolnym czasie aktywnie działa na rzecz integrowania studentów i uczelni z biznesem w założonej przez siebie Fundacji Kanał Studencki. Zawodowo zajmuje się project managementem i service designem. Prywatnie kojarzona z ogromną ilością energii i uwielbieniem Roberta Lewandowskiego.",
        title: 'Koordynatorka wolontariuszy',
        linkedin: 'https://www.linkedin.com/in/aleksandra-benowska/',
    },
];

const VolunteerProfiles = () => {
    return (
        <section className="text-center bg-LightBlue py-10">
            <h2 className="text-3xl font-bold mb-6">Wolontariusze</h2>
            <div className="w-11/12 mx-auto">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 max-w-3xl mx-auto">
                    {volunteers.map((volunteer) => (
                        <div key={volunteer.id} className="p-4 bg-white rounded-lg shadow-md">
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/speakers/${volunteer.photo}`}
                                className={`w-32 h-32 rounded-full mx-auto mb-4 object-cover ${volunteer.photo === 'aleksandra_benowska.webp' ? 'object-center' : 'object-top'
                                    }`}
                            />
                            <h3 className="text-xl font-semibold">
                                {volunteer.firstName} {volunteer.lastName}
                            </h3>
                            <p className="text-sm">{volunteer.title}</p>
                            <div className="flex justify-center mt-4">
                                {volunteer.linkedin && (
                                    <a
                                        href={volunteer.linkedin}
                                        className="mx-2"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaLinkedin />
                                    </a>
                                )}
                                <BioModal speaker={volunteer} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default VolunteerProfiles;