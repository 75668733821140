import React from "react";
import NetworkingBoosterPng from "../assets/networking_booster.png"; // Adjust the path as necessary

const NetworkingBooster = () => {
  return (
    <section className="text-center py-5 px-4 md:px-20" style={{ backgroundColor: '#0097b2' }}>
      <div className="w-11/12 mx-auto flex flex-col md:flex-row items-center gap-4">
        <img
          src={NetworkingBoosterPng}
          alt="Ikona NetworkingBooster"
          className="object-contain w-full sm:w-3/4 md:w-1/4 lg:w-1/8 h-auto p-2 rounded-lg" // Adjusted sizes
        />
        <div className="flex flex-col items-center">
          <p className="mt-4 text-white text-xl">
            Ułatwiaj nawiązywanie kontaktów dzięki koncepcji Networking Booster. Dołącz do specjalnych stref i korzystaj z kart konwersacyjnych, aby poznać nowych ludzi na DevFest.
          </p>
        </div>
      </div>
    </section>
  );
};

export default NetworkingBooster;