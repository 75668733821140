import React from "react";
import Hero from "../components/hero.js";
import About from "../components/about.js";
import SpeakerCarousel from "../components/speakers_carousel.js";
import SponsorLogos from "../components/sponsors.js";
import LocationSection from "../components/location.js";
import PathsSection from "../components/paths.js";
import EventNumbersSection from "../components/debates.js";
import DebatesSection from "../components/debates.js";
import RobotSection from "../components/robot.js";
import NetworkingBooster from "../components/networking_booster.js";
import VolunteerProfiles from "../components/volunteers.js";
import OrganizersProfiles from "../components/organizers";

const Home = () => (
  <div>
    <Hero />
    {/* <About /> */}
    <SpeakerCarousel />
    <DebatesSection />
    <RobotSection />
    <NetworkingBooster />
    <LocationSection />
    <PathsSection />
    <VolunteerProfiles />
    <SponsorLogos />
      <OrganizersProfiles/>

  </div>
);

export default Home;
