import React from "react";
import { Link } from "react-router-dom";
import GoogleLogo from "../assets/sponsors/Google_2015_logo.webp";
import Me4HLogo from "../assets/sponsors/me4h_logo.webp";
import PhDLogo from "../assets/partners/phd_logo_cropped.webp";
import TrenerSpokojuLogo from "../assets/partners/trener_spokoju_logo_cropped.webp";
import PandaTeamLogo from "../assets/partners/panda_team_logo.webp";
import KanalStudenckiLogo from "../assets/partners/Kanal_studencki-logo-kolor.webp";
import GftLogo from "../assets/sponsors/Gft_logo.webp";
import SnowdogLogo from "../assets/partners/snowdog_logo.svg";
import VmlLogo from "../assets/sponsors/vml_logo.webp";
import SiiLogo from "../assets/sponsors/sii_logo.webp";
import AppchanceLogo from "../assets/sponsors/appchance_logo.webp";
import AllegroTechLogo from "../assets/sponsors/allegro_tech_logo.webp";
import KtAcademyLogo from "../assets/partners/kt_academy_logo.webp";
import DataRabbitLogo from "../assets/sponsors/datarabbit_logo_color.webp";
import LaurensCosterLogo from "../assets/partners/laurenscoster_logo.svg";
import InvestPoznanLogo from "../assets/partners/invest_poznan_logo.webp";
import PdLogo from "../assets/partners/programuj_d_logo.webp";
import KempinskiLogo from "../assets/partners/kempinski_logo.webp";
import TalentAlphaLogo from "../assets/partners/talent_alpha_logo.webp";
import FlynerdLogo from "../assets/partners/flynerd_logo.webp";
import WomenMachineLogo from "../assets/partners/women_machine_logo.webp";

const sponsorLogoStyle = {
  width: "300px",
  height: "150px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "2px solid #e0e0e0",
  borderRadius: "8px",
  padding: "10px",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
};

const SponsorLogos = () => (
  <div className="text-center my-20">
    <h2>Sponsorzy</h2>
    {/* <p>Thank you to our sponsors for their generous support.</p> */}

    <section className="my-10">
      <div className="flex flex-col w-full h-full px-10 gap-10 items-center">
        {/* Platinum Section */}
        <span className="bg-[#b5cedc] text-white px-4  rounded-md w-5/6">
          <h3 className="text-2xl font-normal">Sponsor platynowy</h3>
        </span>
        <div className="flex justify-center items-center w-auto">
          <div style={sponsorLogoStyle}>
            <img
              src={GoogleLogo}
              alt="Google Logo"
              className="object-contain h-24"
            />
          </div>
        </div>

        {/* Gold Section */}

        <span className="bg-[#dcc26bcb] text-white px-4  rounded-md w-5/6">
          <h3 className="text-2xl font-normal">Sponsorzy złoci</h3>
        </span>
        <div className="flex flex-wrap justify-center gap-10 w-auto">
          <div style={sponsorLogoStyle}>
            <img
              src={AllegroTechLogo}
              alt="Allegro Tech Logo"
              className="object-contain h-24"
            />
          </div>
          <div style={sponsorLogoStyle}>
            <img src={GftLogo} alt="GFT Logo" className="object-contain h-24" />{" "}
          </div>
          <div style={sponsorLogoStyle}>
            <img
              src={Me4HLogo}
              alt="Me4H Logo"
              className="object-contain h-28"
            />{" "}
          </div>
        </div>

        {/* Silver Section */}

        <span className="bg-[#C0C0C0] text-white px-4  rounded-md w-5/6">
          <h3 className="text-2xl font-normal">Sponsorzy srebrni</h3>
        </span>
        <div className="flex justify-center w-auto">
          <div style={sponsorLogoStyle}>
            <img src={VmlLogo} alt="VML Logo" className="object-contain h-24" />
          </div>
        </div>

        {/* Bronze Section */}

        <span className="bg-[#6f4f289f] text-white px-4  rounded-md w-5/6">
          <h3 className="text-2xl font-normal">Sponsorzy brązowi</h3>
        </span>
        <div className="flex flex-wrap justify-center gap-10 w-auto">
          <div style={sponsorLogoStyle}>
            <img src={SiiLogo} alt="Sii Logo" className="object-contain h-24" />
          </div>
          <div style={sponsorLogoStyle}>
            <img
              src={AppchanceLogo}
              alt="Appchance Logo"
              className="object-contain h-24"
            />
          </div>
          <div style={sponsorLogoStyle}>
            <img
              src={DataRabbitLogo}
              alt="DataRabbit Logo"
              className="object-contain h-24"
            />
          </div>
        </div>
      </div>
    </section>

    <section className="my-20">
      <h2 className="my-10 font-semibold">Partnerzy</h2>
      <div className="flex flex-wrap justify-center gap-10 w-auto">

        <div style={sponsorLogoStyle}>
          <img
            src={TrenerSpokojuLogo}
            alt="TrenerSpokoju Logo"
            className="object-contain max-h-28"
          />
        </div>

        <div style={sponsorLogoStyle}>
          <img
            src={PhDLogo}
            alt="PhD Logo"
            className="object-contain max-h-28"
          />
        </div>


        <div style={sponsorLogoStyle}>
          <img
            src={PandaTeamLogo}
            alt="PandaTeam Logo"
            className="object-contain max-h-28"
          />
        </div>

        <div style={sponsorLogoStyle}>
          <img
            src={KanalStudenckiLogo}
            alt="KanalStudencki Logo"
            className="object-contain max-h-28"
          />
        </div>

        <div style={sponsorLogoStyle}>
          <img
            src={SnowdogLogo}
            alt="Snowdog Logo"
            className="object-contain max-h-28"
          />
        </div>

        <div style={sponsorLogoStyle}>
          <img
            src={KtAcademyLogo}
            alt="Kt Academy Logo"
            className="object-contain max-h-28"
          />
        </div>

        <div style={sponsorLogoStyle}>
          <img
            src={LaurensCosterLogo}
            alt="LaurensCoster Logo"
            className="object-contain max-h-28"
          />
        </div>

        <div style={sponsorLogoStyle}>
          <img
            src={InvestPoznanLogo}
            alt="Invest Poznan Logo"
            className="object-contain max-h-28"
          />
        </div>

        <div style={sponsorLogoStyle}>
          <img
            src={PdLogo}
            alt="Programuj Dziewczyno Logo"
            className="object-contain max-h-28"
          />
        </div>

        <div style={sponsorLogoStyle}>
          <img
            src={KempinskiLogo}
            alt="Kempinski Logo"
            className="object-contain max-h-28"
          />
        </div>

        <div style={sponsorLogoStyle}>
          <img
            src={TalentAlphaLogo}
            alt="Talent Alpha Logo"
            className="object-contain max-h-28"
          />
        </div>

        <div style={sponsorLogoStyle}>
          <img
            src={FlynerdLogo}
            alt="Flynerd Logo"
            className="object-contain max-h-28"
          />
        </div>

        <div style={sponsorLogoStyle}>
          <img
            src={WomenMachineLogo}
            alt="Women Machine Logo"
            className="object-contain max-h-28"
          />
        </div>

      </div>
    </section>

    {/* <div className="m-16">
      <Link
        to="/sponsors"
        className="text-white bg-Green500 hover:text-white rounded-full text-md px-6 py-4"
      >
        Become a Sponsor
      </Link>
    </div> */}
  </div>
);

export default SponsorLogos;
