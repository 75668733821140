import React from 'react';

const SpeakerBio = ( { speaker }) => {
    
    return(
        <>
                <div key={speaker.id} className="p-4 bg-white rounded-lg shadow-md">
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/speakers/${speaker.photo}`}
                        className="w-32 h-32 rounded-full mx-auto mb-4 object-cover object-top"
                    />
                    <h3 className='font-semibold'>{speaker.firstName} {speaker.lastName}</h3>
                    <h4>{speaker.tagLine}</h4>
                    <p className='text-md'>{speaker.bio}</p>
                </div>

            </>

            )
            }
            export default SpeakerBio