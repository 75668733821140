import React from 'react';
import gif from '../assets/DevFest_SaveTheDate_01.gif';

const Hero = () => (
    <section className='w-11/12 sm:my-5 mx-auto grid grid-cols-1 lg:grid-cols-2'>
        <div className='my-5 mx-auto'>
            <img src={gif} className="mx-auto" alt='DevFest Save The Date Gif'></img>
        </div>
        <div className='mx-auto text-center sm:my-5 w-11/12'>
            <h1>DevFest {process.env.REACT_APP_CHAPTER_NAME} <br />
            </h1>
            <h3 className='text-Green500 font-semibold animate-pulse'>{process.env.REACT_APP_DEVFEST_DATE}</h3>
            <p className='sm:px-5 sm:text-lg'>
                DevFest {process.env.REACT_APP_CHAPTER_NAME} to wyjątkowe wydarzenie technologiczne skierowane do programistów, liderów zespołów oraz wszystkich, którzy interesują się przyszłością branży IT. W tegorocznej edycji skupiamy się nie tylko na technologii, ale także na zdrowiu psychicznym i rozwoju osobistym pracowników IT. Oprócz sesji technicznych, zapraszamy na interaktywne debaty oraz mentoring psychologiczny.
            </p>
            <div className="flex flex-wrap justify-center items-center gap-5 lg:gap-10 mt-5">
                <div className="bg-white border-4 border-blue-500 rounded-full w-32 h-32 shadow-md flex items-center justify-center p-2">
                    <div className="text-lg text-blue-500 text-center">
                        <span className="font-bold">350+</span><br />uczestników
                    </div>
                </div>
                <div className="bg-white border-4 border-green-500 rounded-full w-32 h-32 shadow-md flex items-center justify-center p-2">
                    <div className="text-lg text-green-500 text-center">
                        <span className="font-bold">20+</span><br />prelegentów
                    </div>
                </div>
                <div className="bg-white border-4 border-orange-500 rounded-full w-32 h-32 shadow-md flex items-center justify-center p-2">
                    <div className="text-lg text-orange-500 text-center">
                        <span className="font-bold">1</span><br />robot
                    </div>
                </div>
            </div>
        </div>
    </section>
);

export default Hero;