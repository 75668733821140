import React from 'react';

const DebatesSection = () => {
  return (
    <>
      <section className='text-center bg-orange-400 py-10'>
        <h2 className='text-white font-bold text-center mt-1 mb-8'>Debata i panele dyskusyjne</h2>
        <div className='w-11/12 mx-auto flex flex-col lg:flex-row gap-6'>

          {/* Debata Oksfordzka */}
          <div className='bg-white rounded-lg p-6 shadow-md flex-1'>
            <h3 className='text-xl font-semibold text-orange-500'>Debata Oksfordzka</h3>
            <p className='mt-2 text-black'>
              Ścieżka managerska jest korzystniejsza niż ekspercka. Moderuje Marta Drozdowska. Publiczność głosuje online, a eksperci wymieniają się argumentami.
              Ścieżka managerska: Natalia Buczel, Mariusz Zyśk, Maciej Majchrzak.
              Ścieżka ekspercka: Rita Łyczywek, Andrzej Siemion, Radek Wojtysiak.
            </p>
          </div>

          {/* Debata o AI */}
          <div className='bg-white rounded-lg p-6 shadow-md flex-1'>
            <h3 className='text-xl font-semibold text-orange-500'>Czy możemy zjeść ciastko i mieć ciastko? Innowacje, etyka i prawo w dobie sztucznej inteligencji.</h3>
            <p className='mt-2 text-black'>
              Prowadząca: Anna Proch. Paneliści: Marek Śliwiński, Dota Szymborska, Michał Jackowski, Michał Zacher, Krystian Bergmann.
            </p>
          </div>

          {/* Debata o zdrowiu psychicznym w IT */}
          <div className='bg-white rounded-lg p-6 shadow-md flex-1'>
            <h3 className='text-xl font-semibold text-orange-500'>IT z sercem: o zdrowiu psychicznym, różnorodności i rolach damsko-męskich.</h3>
            <p className='mt-2 text-black'>
              Prowadząca: Ewelina Skowron. Paneliści: Paweł Węgier, Marta Drozdowska, Magdalena Moskal, Filip Tarachowicz.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default DebatesSection;