import React from "react";
import {
    Modal,
    Box,
    Typography,
    IconButton,
    Avatar,
    useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SpeakersData from "./speakers_data";

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    width: 800, // Fixed width for larger screens
    maxWidth: "90%", // Responsive max width for mobile
    maxHeight:"80%",
    overflow: "auto",
};

const getSpeakerData = (session) => {
    return SpeakersData.find((speaker) => {
        return `${speaker.firstName} ${speaker.lastName}` === session.speaker;
    });
};

const SpeakerModal = ({ open, handleClose, session, speakerData }) => {
    const isMobile = useMediaQuery("(max-width:600px)");

    if (!session ) return null;
    if (session.length === 2){
        const speaker1Data = session ? getSpeakerData(session[0]) : null;
        const speaker2Data = session ? getSpeakerData(session[1]): null;

        return (
            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        ...modalStyle,
                        width: isMobile ? '90%' : 800, // Adjust width based on mobile
                        p: isMobile ? 2 : 4, // Adjust padding based on mobile
                    }}
                >
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" component="h2" align="center">
                        {speaker1Data.presentation}
                    </Typography>

                    <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
                        <Avatar
                            src={`${process.env.PUBLIC_URL}/assets/speakers/${speaker1Data.photo}`} // Update to include speaker photo
                            alt={speaker1Data.firstName}
                            sx={{ width: 56, height: 56, marginRight: 2 }}
                        />
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                            {speaker1Data.firstName} {speaker1Data.lastName}
                        </Typography>
                        <Avatar
                            src={`${process.env.PUBLIC_URL}/assets/speakers/${speaker2Data.photo}`} // Update to include speaker photo
                            alt={speaker2Data.firstName}
                            sx={{ width: 56, height: 56, marginRight: 2 }}
                        />
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                            {speaker2Data.firstName} {speaker2Data.lastName}
                        </Typography>
                    </Box>

                    <Typography variant="h6" sx={{ mt: 2 }}>
                        Abstract:
                    </Typography>
                    <Typography sx={{ mt: 1 }} whiteSpace={"break-spaces"}>
                        {speaker1Data.abstract || "No abstract available."}
                    </Typography>

                    <Typography variant="h6" sx={{ mt: 2 }}>
                        {speaker1Data.firstName} {speaker1Data.lastName} Bio:
                    </Typography>
                    <Typography sx={{ mt: 2 }} whiteSpace={"break-spaces"}>
                        {speaker1Data.bio}
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 2 }}>
                        {speaker2Data.firstName} {speaker2Data.lastName} Bio:
                    </Typography>
                    <Typography sx={{ mt: 2 }} whiteSpace={"break-spaces"}>
                        {speaker2Data.bio}
                    </Typography>

                    <Typography sx={{ mt: 2 }}>
                        Language: {speaker1Data.language}
                    </Typography>
                </Box>
            </Modal>
        );
    }
    speakerData = getSpeakerData(session)
    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    ...modalStyle,
                    width: isMobile ? '90%' : 800, // Adjust width based on mobile
                    p: isMobile ? 2 : 4, // Adjust padding based on mobile
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" component="h2" align="center">
                    {speakerData.presentation}
                </Typography>

                <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
                    <Avatar
                        src={`${process.env.PUBLIC_URL}/assets/speakers/${speakerData.photo}`} // Update to include speaker photo
                        alt={speakerData.firstName}
                        sx={{ width: 56, height: 56, marginRight: 2 }}
                    />
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {speakerData.firstName} {speakerData.lastName}
                    </Typography>
                </Box>

                <Typography variant="h6" sx={{ mt: 2 }}>
                    Abstract:
                </Typography>
                <Typography sx={{ mt: 1 }}>
                    {speakerData.abstract || "No abstract available."}
                </Typography>
                <Typography variant="h6" sx={{ mt: 2 }}>
                    Bio:
                </Typography>
                <Typography sx={{ mt: 2 }} whiteSpace={"break-spaces"}>
                    {speakerData.bio}
                </Typography>

                <Typography sx={{ mt: 2 }}>
                    Language: {speakerData.language}
                </Typography>
            </Box>
        </Modal>
    );
};

export default SpeakerModal;
