import React from 'react';
import { FaLinkedin } from 'react-icons/fa';
import BioModal from './modal';

const volunteers = [
    {
        id: 1,
        firstName: 'Jakub',
        lastName: 'Sobczak',
        photo: 'jakub_sobczak.webp',
        bio: "Zawodowo inżynier oprogramowania w chess.com (zapytaj mnie o partyjkę :)). Prywatnie miłośnik projektów open source i motoryzacji. W wolnym czasie organizator spotkań GDG Poznań, gdzie wspólnie z innymi pasjonatami technologii organizujemy ciekawe wydarzenia dla społeczności IT w Poznaniu.",
        title: 'GDG Poznań organizer',
        linkedin: 'https://www.linkedin.com/in/jasobczak/',
    },
    {
        id: 2,
        firstName: 'Weronika',
        lastName: 'Witek',
        bio: "👩‍💻 Big Data Developer ☁ Cloud 🏅GCP Professional Data Engineer 👩‍🏫 Mentor 🎤 Speaker ⭐ GDG Poznań Organizer 💻 Women Techmakers Ambassador 🙋‍♀️ IT Girls 👉 DareIT 👉 TechLeaders",
        photo: 'weronika_witek.webp',
        title: 'GDG Poznań organizer',
        linkedin: 'https://www.linkedin.com/in/weronika-witek/',
    },
    {
        id: 3,
        firstName: 'Ewelina',
        lastName: 'Skowron',
        bio: "Programistka, której pasją jest tworzenie innowacyjnych produktów zmieniających życie ludzi. Już od początku swojej kariery zakochała się w Androidzie, widząc w nim nieograniczone możliwości rozwoju. Wierzy, że technologia powinna być narzędziem do rozwiązywania realnych problemów ludzi. W swojej pracy kładzie duży nacisk na jakość, użyteczność i dostępność tworzonych rozwiązań. Dodatkowo angażuje się jako mentorka oraz organizatorka w GDG, gdzie dzieli się swoją wiedzą i wspiera rozwój społeczności technologicznej. Jest również zwolenniczką zdrowia psychicznego, uważając, że tylko wypoczęty i zdrowy umysł może generować wartościowe pomysły i rozwiązania.",
        photo: 'ewelina_skowron.webp',
        title: 'GDG Poznań organizer',
        linkedin: 'https://www.linkedin.com/in/ewelinaskowron/',
    },
    {
        id: 4,
        firstName: 'Karol',
        lastName: 'Gruetzmacher',
        photo: 'karol_gruetzmacher.webp',
        bio: "Mobile Engineer, Co-organizer of GDG Poznań",
        title: 'GDG Poznań organizer',
        linkedin: 'https://www.linkedin.com/in/karol-gruetzmacher/',
    },
];

const OrganizersProfiles = () => {
    return (
        <section className="text-center bg-LightBlue py-10">
            <h2 className="text-3xl font-bold mb-6">Organizatorzy</h2>
            <div className="w-11/12 mx-auto">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 max-w-3xl mx-auto">
                    {volunteers.map((volunteer) => (
                        <div key={volunteer.id} className="p-4 bg-white rounded-lg shadow-md">
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/speakers/${volunteer.photo}`}
                                className={`w-32 h-32 rounded-full mx-auto mb-4 object-cover ${volunteer.photo === 'aleksandra_benowska.webp' ? 'object-center' : 'object-top'
                                    }`}
                            />
                            <h3 className="text-xl font-semibold">
                                {volunteer.firstName} {volunteer.lastName}
                            </h3>
                            <p className="text-sm">{volunteer.title}</p>
                            <div className="flex justify-center mt-4">
                                {volunteer.linkedin && (
                                    <a
                                        href={volunteer.linkedin}
                                        className="mx-2"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaLinkedin />
                                    </a>
                                )}
                                <BioModal speaker={volunteer} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default OrganizersProfiles;