import React, {useEffect, useState} from "react";
import Papa from "papaparse";
import {
    Avatar,
    Box,
    Container,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
} from "@mui/material";
import Speaker_modal from "../components/speaker_modal";
import SpeakersData from "../components/speakers_data";

// Path to your CSV file in the "public" folder
const csvFilePath = "/assets/schedule.csv";
const imagesBasePath = "/assets/speakers/";

const getFlagForLanguage = (language) => {
    switch (language) {
        case "ENG":
            return "🇬🇧"; // UK Flag for English
        case "PL":
            return "🇵🇱"; // Polish Flag for Polish
        default:
            return ""; // No flag for unknown languages
    }
};

const getSpeakerImage = (imageName) => {
    return `${imagesBasePath}${imageName}`; // Construct the full image path
};

const Schedule = () => {
    const [data, setData] = useState([]); // Initialize as an empty array
    const [modalOpen, setModalOpen] = useState(false); // State to manage modal visibility
    const [selectedSession, setSelectedSession] = useState(null); // Session to be displayed in the modal
    const [selectedSpeakerData, setSelectedSpeakerData] = useState(null); // Speaker data for modal
    const rooms = ["Software Stage", "Cloud&AI Stage", "Mobile Stage", "Mental Health Stage"];

    const isMobile = useMediaQuery("(max-width:600px)"); // Check if the device is mobile

    // Fetch and parse the CSV file using PapaParse
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(csvFilePath);
                const reader = response.body.getReader();
                const result = await reader.read();
                const decoder = new TextDecoder("utf-8");
                const csvData = decoder.decode(result.value);
                Papa.parse(csvData, {
                    header: true,
                    skipEmptyLines: true,
                    complete: (results) => {
                        setData(results.data); // Ensure the parsed data is set
                    },
                });
            } catch (error) {
                console.error("Error fetching or parsing CSV data:", error);
            }
        };
        fetchData();
    }, []);

    const getUniqueTimeSlots = () => {
        return [...new Set(data.map((session) => session.time))].sort();
    };

    const getSessionForRoomAndTime = (room, time) => {

        var first =  data.find((session) => session.room === room && session.time === time);
        var second = data.findLast((session) => session.room === room && session.time === time);

        if (first === second){
            return [first]
        }
        else {
            return [first, second]
        }

    };

    const getSpeakerData = (session) => {
        return SpeakersData.find((speaker) => {
            return `${speaker.firstName} ${speaker.lastName}` === session.speaker;
        });
    };

    const handleTitleClick = (session) => {
        const speakerData = getSpeakerData(session);
        setSelectedSession(session);
        setSelectedSpeakerData(speakerData);
        setModalOpen(true); // Open the modal
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom align="center">
            </Typography>
            <Typography variant="h4" gutterBottom align="center">
                Conference Schedule
            </Typography>
            <Paper style={{margin: "20px 0", padding: "20px"}}>
                {isMobile ? (
                    // Mobile View
                    <>
                    <Box key="8:30" mb={2} sx={{p: 2, border: '0.5px dashed grey'}}>
                        <Typography variant="h6">8:00</Typography>
                        <Typography variant="h6" align="center">
                            Registration
                        </Typography>
                    </Box>
                        <Box key="9:30" mb={2} sx={{p: 2, border: '0.5px dashed grey'}}>
                            <Typography variant="h6">9:30</Typography>
                            <Typography variant="h6" align="center">
                                Welcome and Introduction
                            </Typography>
                        </Box>
                    <Box key="9:50" mb={2} sx={{p: 2, border: '0.5px dashed grey'}}>
                        <Typography variant="h6">9:30</Typography>
                        <Typography variant="h6" align="center">
                            Keynotes: Debata Oksfordzka - Która ścieżka rozwoju jest lepsza - techniczna czy liderską?
                        </Typography>
                    </Box>
                    {getUniqueTimeSlots().map((time) => (
                        <Box key={time} mb={2} sx={{p: 2, border: '0.5px dashed grey'}}>
                            <Typography variant="h6">{time}</Typography>
                            {rooms.map((room) => {
                                var session = getSessionForRoomAndTime(room, time);
                                if (session.length === 2){
                                    const speaker1Data = session ? getSpeakerData(session[0]) : null;
                                    const speaker2Data = session ? getSpeakerData(session[1]): null;

                                    return (
                                                <Box key={room} mb={1}>
                                                    <Typography variant="h6" sx={{mt: 2}}>{room}: </Typography>
                                                    <Avatar
                                                        src={getSpeakerImage(speaker1Data?.photo)}
                                                        alt="Speaker"
                                                        style={{
                                                            marginRight: "8px",
                                                            width: "40px",
                                                            height: "40px"
                                                        }}
                                                    />
                                                    <Typography
                                                        variant="body1"
                                                        onClick={() => session && handleTitleClick(session)}
                                                        style={{
                                                            cursor: session ? "pointer" : "default",
                                                        }}
                                                    >
                                                        {speaker1Data ? speaker1Data.presentation : "No session"}
                                                        {speaker1Data && ` ${getFlagForLanguage(speaker1Data.language)}`}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        {speaker1Data ? speaker1Data.firstName : "No"} {speaker1Data ? speaker1Data.lastName : "Speaker"}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        {speaker2Data ? speaker2Data.firstName : "No"} {speaker2Data ? speaker2Data.lastName : "Speaker"}
                                                    </Typography>
                                                </Box>
                                            )

                                }
                                session = session[0]
                                const speakerData = session ? getSpeakerData(session) : null;
                                if (session !== undefined && room ==="Software Stage" &&  session.activity !== undefined) {
                                    return <TableCell key={room}>
                                        <Typography variant="body1" style={{cursor: "pointer"}}>
                                            {session.activity}
                                        </Typography>
                                    </TableCell>
                                }
                                if (session !== undefined && room !=="Software Stage" &&  session.activity !== undefined) {
                                    return <></>
                                }
                                return (
                                    <Box key={room} mb={1}>
                                        <Typography variant="h6" sx={{mt: 2}}>{room}: </Typography>
                                        <Avatar
                                            src={getSpeakerImage(speakerData?.photo)}
                                            alt="Speaker"
                                            style={{
                                                marginRight: "8px",
                                                width: "40px",
                                                height: "40px"
                                            }}
                                        />
                                        <Typography
                                            variant="body1"
                                            onClick={() => session && handleTitleClick(session)}
                                            style={{
                                                cursor: session ? "pointer" : "default",
                                            }}
                                        >
                                            {speakerData ? speakerData.presentation : "No session"}
                                            {speakerData && ` ${getFlagForLanguage(speakerData.language)}`}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {speakerData ? speakerData.firstName : "No"} {speakerData ? speakerData.lastName : "Speaker"}
                                        </Typography>
                                    </Box>
                                );

                            })}
                        </Box>

                    )
                    )}
                        <Box key="17:30" mb={2} sx={{p: 2, border: '0.5px dashed grey'}}>
                            <Typography variant="h6">17:30</Typography>
                            <Typography variant="h6" align="center">
                                Closing Ceremony and Quiz competition 🏅
                            </Typography>
                        </Box>
                    </>
                ) : (
                    // Desktop View
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Time</TableCell>
                                {rooms.map((room) => (
                                    <TableCell key={room}>{room}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>08:00</TableCell>
                                <TableCell colSpan={rooms.length}>
                                    <Typography variant="h6" align="center">
                                        Registration
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>09:30</TableCell>
                                <TableCell colSpan={rooms.length}>
                                    <Typography variant="h6" align="center">
                                        Welcome and Introduction
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>09:50</TableCell>
                                <TableCell colSpan={rooms.length}>
                                    <Typography variant="h6" align="center">
                                        Keynotes: Debata Oksfordzka - Która ścieżka rozwoju jest lepsza - techniczna czy liderską?
                                    </Typography>
                                    <Typography variant="h6" align="center">
                                        Software Stage
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            {/* Conference schedule rows */}
                            {getUniqueTimeSlots().map((time) => (
                                <TableRow key={time}>
                                    <TableCell>{time}</TableCell>
                                    {rooms.map((room) => {
                                        var session = getSessionForRoomAndTime(room, time);
                                        if (session.length === 2){
                                            const speaker1Data = session ? getSpeakerData(session[0]) : null;
                                            const speaker2Data = session ? getSpeakerData(session[1]): null;

                                            return (
                                                <TableCell key={room}>
                                                    {session ? (
                                                        <Box>
                                                            <Box display="flex" alignItems="center"
                                                                 onClick={() => handleTitleClick(session)}>
                                                                <Avatar
                                                                    src=""
                                                                    alt={session.speaker}
                                                                    style={{
                                                                        marginRight: "8px",
                                                                        width: "40px",
                                                                        height: "40px"
                                                                    }}
                                                                />
                                                                <Typography variant="body1" style={{cursor: "pointer"}}>
                                                                    {speaker1Data ? speaker1Data.presentation : "Unknown title"}
                                                                </Typography>
                                                                <Typography variant="body2">
                                                                    {speaker1Data && `${getFlagForLanguage(speaker1Data.language)}`}
                                                                </Typography>
                                                            </Box>
                                                            <Typography variant="body2" color="textSecondary">
                                                                {speaker1Data.firstName} {speaker1Data.lastName}
                                                            </Typography>
                                                            <Typography variant="body2" color="textSecondary">
                                                                {speaker2Data.firstName} {speaker2Data.lastName}
                                                            </Typography>
                                                        </Box>
                                                    ) : (
                                                        "No session"
                                                    )}
                                                </TableCell>
                                            )
                                        }
                                        if (session.length === 1){
                                            session = session[0]
                                        }
                                        const speakerData = session ? getSpeakerData(session) : null;
                                        if (session !== undefined && session.activity !== undefined) {
                                            return <TableCell key={room}>
                                                <Typography variant="body1" style={{cursor: "pointer"}}>
                                                    {session.activity}
                                                </Typography>
                                            </TableCell>
                                        }
                                        return (
                                            <TableCell key={room}>
                                                {session ? (
                                                    <Box>
                                                        <Box display="flex" alignItems="center"
                                                             onClick={() => handleTitleClick(session)}>
                                                            <Avatar
                                                                src={getSpeakerImage(speakerData?.photo)} // Use image from CSV
                                                                alt={session.speaker}
                                                                style={{
                                                                    marginRight: "8px",
                                                                    width: "40px",
                                                                    height: "40px"
                                                                }}
                                                            />
                                                            <Typography variant="body1" style={{cursor: "pointer"}}>
                                                                {speakerData ? speakerData.presentation : "Unknown title"}
                                                            </Typography>
                                                            <Typography variant="body2">
                                                                {speakerData && `${getFlagForLanguage(speakerData.language)}`}
                                                            </Typography>
                                                        </Box>
                                                        <Typography variant="body2" color="textSecondary">
                                                            {speakerData.firstName} {speakerData.lastName}
                                                        </Typography>
                                                    </Box>
                                                ) : (
                                                    "No session"
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell>17:30</TableCell>
                                <TableCell colSpan={rooms.length}>
                                    <Typography variant="h6" align="center">
                                        Closing Ceremony and Quiz competition 🏅
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                )}
            </Paper>

            {/* Speaker Modal */}
            <Speaker_modal
                open={modalOpen}
                handleClose={() => setModalOpen(false)}
                session={selectedSession}
                speakerData={selectedSpeakerData}
            />
        </Container>
    );
};

export default Schedule;
