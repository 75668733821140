import React from 'react';
import SpeakerProfiles from "../components/speakers_profiles";

const Speakers = () => (
    <>
    <SpeakerProfiles />
    </>

   
);

export default Speakers;